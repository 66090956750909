import request from './base/request';

export const getListPermissionApi = (params) => {
  return request
    .get('/permission', { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const createPermissionApi = (data, id) => {
  return request
    .post(`/permission/${id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updatePermissionApi = (data) => {
  return request
    .put(`/permission`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
