<template>
  <div>
    <div
          class="category-table main-table custom-table permission-table"
          :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
        >
          <data-table
            id="frozen-column"
            v-show="isGotLayer"
            :data-source="listData"
            :init-grid="initializeGrid"
            :init-picker="initializedPicker"
            :grid-columns="gridColumns"
            :allowAddNew="false"
            :totalData="totalData - 1"
            :lastedRow="lastedRow"
            :showFocus="false"
            :isHasData="isHasData"
            :indexColumnFrozen=3
            :isPermissionProductScreen="true"
            :filterColumns="filterColumns"
            :isResizeCustome="false"
            @onHandleActionTable="checkValidationData"
            class="table-viewer"
          />
        </div>
        <dialog-popup
          :dialog="dialogPopup"
          :message="dialogMessage"
          @submit="() => checkboxHandler(false, currentBinding)"
          @close="closePopup"
        />
        <notification-popup :dialog="dialogNotification" :message="messageSavedChange" @submit="dialogNotification = false" />
          <SelectProducts
            :key="keyRender"
            :dialog.sync="selectionShow"
            :dataList="dataList"
            :selectedData="columBindings"
            :itemCount="dataList.length - 1"
            :placeholder="$t('viewer_management.title_select_product_to_display')"
            :hasSearch="true"
            @updateProductSelected="onHandleSelectData"
          />
  </div>
  
</template>
  
<script>
import "@mescius/wijmo.styles/wijmo.css";
import "@mescius/wijmo.vue2.grid";
import "@mescius/wijmo.vue2.input";
import { mapActions, mapState } from "vuex";
import { updateProductPermissionApi } from "@/api/product";
import { CollectionView } from "@mescius/wijmo";
import { hidePopup, hasClass, closest, createElement, getElement, removeChild } from "@mescius/wijmo";

import { getListProductPermissionApi } from '@/api/product';
import SelectProducts from '@/components/products/viewer/select-products';
import { GET_ALL } from '@/constants/export-report.js'

import DialogPopup from "@/components/dialogs/question-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import DataTable from "@/components/category/data-table";
import { formatDateTime } from "@/utils/datetimeFormat";
import { UndoStack } from "@mescius/wijmo.undo";
import { GridEditAction } from "@/views/products/managements/wijmo-extends";
import { getUserInfo } from "@/api/auth";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@mescius/wijmo.grid";
import * as wjcCore from "@mescius/wijmo";
import { ROLE } from '@/constants/role';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      flex                : null,
      data                : [],
      listData            : null,
      gridColumns         : [],
      dialogPopup         : false,
      dialogMessage       : this.$t('viewer_management.description_revoke_browsing_permissions'),
      dialogCancelBtn     : false,
      check_status        : null,
      countErr            : 0,
      statusUpdate        : {
        time: null,
        user: null
      },
      totalData           : null,
      undoStack           : null,
      canUndo             : false,
      canRedo             : false,
      actionCount         : 0,
      stackUndoWhenUncheck: null,
      eventChangeStatus   : null,
      lastedRow           : {},
      contractorName      : "",
      showTooltip         : false,
      productMasters      : [],
      columBindings       :      [],
      isGotLayer          : false,
      listOriginalData    : [],
      dialogNotification: false,
      isDragEnabled: false,
      dragSrc: null,
      dragDst: null,
      currentBinding: null,
      bindingSelected: [],
      dataInitital: [],
      filterColumns: [],
      selectionShow: false,
      dataList: [],
      keyRender: 0
    };
  },

  components: {
    DataTable,
    DialogPopup,
    NotificationPopup,
    SelectProducts
  },

  computed: {
    ...mapState("userData", ["contractor", "currentUser"]),
    ...mapState("commonApp", ["loadMore", "isExpand"]),
    ...mapState("actionsTable", ["startAction"]),
    ...mapState("registerData", ["isFullScreen"]),

    isHasData() {
      return this.canUndo || this.canRedo;
    },
    messageSavedChange() {
      return this.$t('viewer_management.message_saved_changes')
    }
  },

  async mounted() {
    this.actionUpdateIsLoading(true)
    await this.updateBreadCrumb(this.items);
    this.gridColumns = this.getGridColumns();
    await this.checkUserInfo();
    this.isGotLayer             = false;
    const permissionApiResponse = await getListProductPermissionApi({ order: "user_id" });
    this.actionUpdateIsLoading(false)
    this.dataInitital = [...permissionApiResponse.data];
    let dataRender = [];
    (permissionApiResponse.data || []).forEach(item => {

      // Convert data to render raw
      let existData = dataRender.find(data => data.user_id === item.user_id);
      if(existData) {
        const newKey = `product_${item.product_master_id}`
        existData[newKey] = item.status;
      } else {
        const key = `product_${item.product_master_id}`
        const object = {};
        object[key] = item.status;
        object['id'] = item.id;
        object['user_id'] = item.user_id;
        object['product_name'] = item.product_name;
        object['email'] = item.email;
        object['role_id'] = item.role_id;
        dataRender.push(object);
      }

      // Get product master to render column
      if (!this.productMasters.find(x => x.id === item.product_master_id)) {
        this.productMasters.push({
          id: item.product_master_id,
          product_name: item.product_name
        });
        this.columBindings.push(item.product_master_id); 
      }
    });
    this.isGotLayer             = true;
    this.statusUpdate           = {
      time: permissionApiResponse.updated_at_latest,
      user: permissionApiResponse.user_updated
    };
    this.$emit('dataChanged', this.statusUpdate);
    this.data = dataRender;
    this.totalData = dataRender.length;
    

    this.defineTable();
    this.createUndoStack();
    if (this.flex) {
      const dataInPicker = [...this.flex.columns];
      let newData = dataInPicker.splice(0,3);
      this.columnPicker.itemsSource = dataInPicker;
      this.columnPicker.checkedMemberPath = 'visible';
      this.columnPicker.displayMemberPath = 'header';
      this.columnPicker.lostFocus.addHandler(() => {
        hidePopup(this.columnPicker.hostElement);
      });
      this.columnPicker.formatItem.addHandler((s, e) => {
        this._enableDragItem(e.item, this.isDragEnabled);
      });
      const host = this.columnPicker.hostElement;
      host.addEventListener('dragstart', this.handleDragStart);
      host.addEventListener('dragover', this.handleDragOver);
      host.addEventListener('drop', this.handleDrop);
      host.addEventListener('dragend', this.handleDragEnd);
      this.filterColumns = ['email']
    }
  },

  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoading"]),
    ...mapActions("actionsTable", ["actionUpdateStatusBtn"]),
    onHandleSelectData(values) {
      let hideColumns = this.productMasters.filter(item => !values.includes(item.id));
      let showColumns = this.productMasters.filter(item => values.includes(item.id));
      this.selectionShow = false;
      hideColumns.forEach(element => {
        this.flex.columns.getColumn(`product_${element.id}`).visible = false;
      });
      showColumns.forEach(element => {
        this.flex.columns.getColumn(`product_${element.id}`).visible = true;
      });
    },
    onCancelSelectData() {
      this.selectionShow= false;
    },

    getWidthByText(string) {
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width      = "auto";
      text.style.position   = "absolute";
      text.style.whiteSpace = "no-wrap";
      text.style.font       = "Source Han Sans";
      text.style.fontSize   = 13 + "px";
      text.innerHTML        = string + "標準値";
      const width           = text.clientWidth;
      document.body.removeChild(text);
      return (width + 12) < 90 ? 90 : width + 12;
    },

    initializedPicker(picker) {
      this.columnPicker = picker;
    },

    defineTable() {
      this.gridColumns       = this.getGridColumns();
      this.listData          = new CollectionView(this.data, {
        trackChanges: true
      });
      this.listOriginalData  = JSON.parse(JSON.stringify(this.listData.items));
      this.flex.columnGroups = this.getGridColumns();
      setMinMaxSizeColumns(this.flex, this.data);
      this.listData.currentItem = null;
      this.dataList = this.productMasters.map(item => {return {...item, 'text': item.product_name, 'hasCheckbox': true}});
      this.dataList.unshift({'id': null, 'text': GET_ALL, 'hasCheckbox': true});
      this.keyRender++;
    },

    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener("keydown", (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.pasted.addHandler((s, e) => {
        if (e.range.row === e.range.row2) {
          // paste one row
          s.onItemsSourceChanged();
        }
      });
      flexgrid?.beginningEdit.addHandler((s, e) => {
        let column        = s.columns[e.col];
        let rowValue      = s.rows[e.row]._data;
        this.check_status = rowValue;
        if (! this.columBindings.includes(Number(column.binding.slice(8)))) {
          return;
        }

        this.stackUndoWhenUncheck = new GridEditAction(s, e);
        if(rowValue.role_id !== ROLE.ADMIN) {
          this.handleCheckBox(rowValue, column.binding);
        }

        e.cancel = true;
      });

      flexgrid?.formatItem.addHandler((s, e) => {
        if (e.panel == s.topLeftCells) {
          e.cell.innerHTML = `<img class="icon-show-hide-product" src="${require('@/assets/icons/product/setting.svg')}"  alt="" />`  + e.cell.innerHTML;
        }
        const colBinding        = e.panel.columns[e.col].binding;
        if(e.panel == s.columnHeaders && colBinding !== 'email') {
          let column        = s.columns[e.col];
          // if (colBinding !== 'product_336') return
          column.allowSorting = false;
          let cnt = 0;
          let countAdminRole = 0;
          for (let i = 0; i < flexgrid.rows.length; i++) {
            const roleId = flexgrid.rows[i].dataItem.role_id;
            if (roleId === ROLE.ADMIN) countAdminRole++;
            if (flexgrid.getCellData(i, e.col) === true && roleId !== ROLE.ADMIN) cnt++;
          }
          e.cell.innerHTML = e.cell.innerHTML + '<input type="checkbox">';
          let cb = e.cell.lastChild; // get input element
          cb.checked =  cnt > 0 && cnt === flexgrid.rows.length - countAdminRole;
          cb.indeterminate = cnt > 0 && cnt < flexgrid.rows.length - countAdminRole;
          // apply checkbox value to cells
          const self = this;
          cb.addEventListener('click', function (e) {
            for (let i = 0; i < flexgrid.rows.length; i++) {
              const roleId = flexgrid.rows[i].dataItem.role_id;
              const stackUndoWhenUncheck = new GridEditAction(s, {
                col: column.index,
                row: i,
              });
              if(roleId !== ROLE.ADMIN) {
                flexgrid.beginUpdate();
                flexgrid.setCellData(i, column.index, cb.checked);
                flexgrid.endUpdate();
                self.undoStack.pushAction(stackUndoWhenUncheck);
              }
            }
            self.bindingSelected.push(colBinding);
          });
        }

        if (e.panel == s.cells && colBinding !== 'email') {
          const roleId = s.rows[e.row]._data?.role_id;
          if(roleId === ROLE.ADMIN) {
            wjcCore.addClass(e.cell, "is-admin-read-only"); 
          }
        }
      });

      // show the column picker when the user clicks the top-left cell
    let ref = flexgrid.hostElement.querySelector(".wj-topleft");
    ref.addEventListener("mousedown", e => {
      if (hasClass(e.target, "icon-show-hide-product")) {
        let host = this.columnPicker.hostElement;
        if (!host.offsetHeight) {
          // showPopup(host, ref, false, true, false);
          // this.columnPicker.focus();
          this.selectionShow = true;
        } else {
          // hidePopup(host, true, true);
          // flexgrid.focus();
        }
        e.preventDefault();
      }
    });
    },
    handleDragStart(e) {
    this.dragSrc = closest(e.target, '.wj-listbox-item');
    if (this.dragSrc) {
      e.dataTransfer.setData('text', this.dragSrc.innerHTML);
      e.dataTransfer.effectAllowed = 'move';
    }
  },
  handleDragOver(e) {
    const dragOver = closest(e.target, '.wj-listbox-item');
    if (this.dragDst && this.dragDst !== dragOver) {
      this._removeDropMarker();
    }
    if (dragOver && dragOver !== this.dragSrc) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
      
      this.dragDst = dragOver;
      
      const src = this._getElementIndex(this.dragSrc);
      const dst = this._getElementIndex(this.dragDst);
      this._removeDropMarker();
      this._addDropMarker(dst > src);
    } else {
      this.dragDst = null;
    }
  },
    handleDrop(e) {
      if (this.dragSrc && this.dragDst) {
        e.preventDefault();
        const src = this._getElementIndex(this.dragSrc);
        const dst = this._getElementIndex(this.dragDst);
        this.flex.columns.moveElement(src, dst);
      }
    },
    handleDragEnd(e) {
        this.dragSrc = null;
        this.dragDst = null;
        this._removeDropMarker();
    },
    enableDrag(e) {
      const element = e.target;
      this.isDragEnabled = element.checked;
      const host = this.columnPicker.hostElement;
      const items = host.getElementsByClassName('wj-listbox-item');
      for (let i = 0; i < items.length; i++) {
        this._enableDragItem(items[i], this.isDragEnabled);
      }
    },
    _enableDragItem(item, enabled) {
      item.setAttribute('draggable', enabled.toString());
    },
    _getElementIndex(element) {
      const parent = element.parentElement;
      const siblings = Array.prototype.slice.call(parent.children);
      return siblings.indexOf(element);
    },
    _removeDropMarker() {
        removeChild(getElement('.drop-marker'));
    },
    _addDropMarker(isAfterPos) {
        const itemsGap = 10;
        const width = 6;
        const margin = itemsGap/width;
        const height = this.dragDst.clientHeight;
        const topPos = this.dragDst.offsetTop;
        const leftPos = isAfterPos 
            ? (this.dragDst.offsetLeft + this.dragDst.clientWidth + margin) 
            : (this.dragDst.offsetLeft - itemsGap + margin);
        const css = `top:${topPos}px;left:${leftPos}px;height:${height}px;width:${width}px`;
        const html = `<div class="drop-marker" style="${css}">&nbsp</div>`;
        createElement(html, this.columnPicker.hostElement);
    },
    getGridColumns() {
      const mapProductMasters = this.productMasters.map((product, index) => {
        const productMasterItem = {
          header      : product.product_name,
          binding     : `product_${product.id}`,
          minWidth    : getWidthByTextContent(product.product_name),
          maxWidth    : 980,
          allowSorting: false,
          dataType    : "Boolean",
          cssClassAll : "no-tooltip single-row product-master",
          wordWrap    : true
        }
        if (index === this.productMasters.length - 1) {
          Object.assign(productMasterItem, {width: '*'})
          delete productMasterItem.maxWidth
        }
        return productMasterItem
      });
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "#",
          binding     : "role_id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : this.$t('viewer_management.table_user_email'),
          binding     : "email",
          minWidth    : 220,
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        ...mapProductMasters
      ];
    },

    handleCheckBox(value, binding) {
      this.currentBinding = binding;
      if (value[binding]) {
        this.dialogPopup     = true;
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true, binding);
      }
    },

    checkboxHandler(check, binding) {
      this.listData.beginUpdate();
      this.listData.currentItem[binding] = check;
      this.listData.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
      this.bindingSelected.push(binding);
      this.closePopup();
    },

    closePopup() {
      this.dialogPopup     = false;
      this.dialogCancelBtn = true;
    },

    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },

    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.contractorName = res.contractor.name;
        })
        .catch(() => {
        });
    },

    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },

    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },

    createUndoStack() {
      this.undoStack = new UndoStack("#undoable-table", {
        maxActions  : 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        }
      });
    },
    checkValidationData() {
      this.submitData();
    },
    submitData() {
      let edited = [];
      let self   = this;
      self.listOriginalData.forEach(function(item, itemIndex) {
        let newItemIndex = self.listData.items.findIndex(currentItem => currentItem.id === item.id);
        let newItem      = self.listData.items[newItemIndex];
        self.bindingSelected.forEach(element => {
          if (newItem[element] !== item[element]) {
          const object = {};
          object['status'] = newItem[element] ? true : false;
          let productMasterId = Number(element.slice(8));
          let dataId = self.dataInitital.find(x => x.user_id === item.user_id && x.product_master_id === productMasterId);
          object['id'] = dataId.id;
          edited.push(object);
        }
        });
      });
      edited = edited.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
      // call api
      if (Object.values(edited).length) {
        updateProductPermissionApi({ data: Object.values(edited) }).then((response) => {
          this.statusUpdate     = {
            time: response.latest_record.updated_at_latest,
            user: response.latest_record.user_updated
          };
          this.$emit('dataChanged', this.statusUpdate);
          this.listOriginalData = JSON.parse(JSON.stringify(this.listData.items));
          this.dialogNotification = true;
          this.undoStack.clear();
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  },
  watch  : {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue
      });
    },
  }
};
</script>
<style lang="scss">
.permission-table {
  .wj-cell {
    &.wj-header {
      input[type=checkbox] {
        position: relative;
        min-width: 16px;
        &:checked {
          &:after {
            position: absolute;
            left: 1px;
            top: -3px;
          }
        }
      }
      &.product-master {
        gap: 8px;
      }
    }
  }
}
</style>